import React from "react"

import Layout from "../components/layout"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/seo"

export default ({ data, location }) => (
  <Layout>
    <SEO
      pagetitle="プライバシーポリシー"
      pagedesc="食べ物についての情報を発信しているサイトです。"
      pagepath={location.pathname}
      // pageimg={data.about.childImageSharp.original.src}
      // pageimgw={data.about.childImageSharp.original.width}
      // pageimgh={data.about.childImageSharp.original.height}
    />
    <article className="content flex-grow">
      <div className="container">
        <h1 className="bar">プライバシーポリシー</h1>
        <div className="postbody">
          <p>当サイトにはGoogle Adsense社の広告（アドセンス広告）を掲載しております。
            アドセンス広告は、広告配信プロセスの中でデータを収集するために、 Cookie（クッキー）やウェブビーコンといった技術を活用しています。
            <br/>
            もし、第三者（Google等）に上記データを送信したくない場合は、お手数ですがブラウザのCookie（クッキー）機能をオフ（無効）にして再度、アクセスして下さい。
            <br/>
            詳しくは、Adsense広告の、
            <a className="text-blue-700 underline" href="https://policies.google.com/privacy?hl=ja" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faExternalLinkAlt}/>
              公式プライバシーポリシー
            </a>
            をご覧下さい。
          </p>
        </div>
      </div>
    </article>
  </Layout>
)
